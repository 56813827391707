import { Button } from 'antd';
import React from 'react';


function Header() {
  return (
    <div id='main'>
        <div className='name'>
          <h2>FULLY MANAGED </h2>
          <h1><span>FINANCIAL SERVICES</span></h1>
          <p className='details'>Apply for the best credit cards, Personal Loan, Savings account, Demat and Home Loan in India 
           </p>
           
           <div className='header-btns'>   
              </div>
        </div>
    </div>
  )
}

export default Header;


// <a href= '/signup' className='header-btn' > Register Now ! </a>
         