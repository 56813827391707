import React from 'react';

const ComingSoon = () => {
  return (
    <div id='offer'>
        <div className='pr-heading'>
            <h1>Coming Soon.....</h1>
            <p className='details'></p>
          
            </div>    
    </div>
  )
}

export default ComingSoon;