// SeparateLayout.js
import React from 'react';
import Offer from '../Components/Offer';

//import FeatureDetailsDes from './FeatureDetailsDes';


const SeparateLayout = ({ children }) => {
  return (
    <div>
      
     
      {children}
      
    </div>
  );
};

export default SeparateLayout;
