import React from 'react';

function offer() {
  return (
    <div id='offer'>
        <div className='pr-heading'>
            <h1>We are here to help you with various financial services with products.</h1>
            <p className='details'></p>
          
            </div>    
    </div>
  )
}

export default offer;



/*  <div className='pr-btns'>
                <a href='#' className='pr-btn'>Sign Up</a>
            </div> */         